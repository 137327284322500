<div class="min-h-screen bg-gray-100 p-8 flex font-poppins main-container">
  <div class="mt-4 p-2 w-[350px] mobile-top-container">
    <div class="shadow-lg mobile-view-margin mb-2 p-2">
      <div>
        <img *ngIf="partnerLogo" [src]="partnerLogo" alt="Partner Logo" class="w-[176px] h-[34px]" /> 
      </div>

      <div>
        <button (click)="logout()" 
                  class="py-2 text-center bg-red-400 mr-4 text-white rounded-lg font-semibold flex items-center justify-center mobile-logout">
              <!-- <img src="assets/icons/logout.svg" alt="Logout Icon" class="w-5 h-5 mr-2" /> -->
              Logout
          </button>
      </div>
    </div>
    <button (click)="viewResults()" class="font-poppins p-2 mt-2 text-[#212529] flex ml-2 back-button">
      <img src="assets/icons/arrow-left.svg" alt="arrow left" class="w-5 h-5 mr-2">
      <div (click)="viewResults()">
        Go Back To Results
      </div>
    </button>
  </div>
  <!-- Sidebar -->
  <div class="w-1/4 bg-white rounded-lg p-6 left-sidebar">
    <div class="flex flex-col items-center user-detail-container">
      <h2 class="text-xl font-bold mt-2">{{ personalDetails.firstName }} {{ personalDetails.lastName }}</h2>
      <div class="text-gray-500 mt-1 text-center">
        <div class="text-sm"><strong>Phone:</strong> {{ personalDetails.phoneNumber }}</div>
        <div *ngIf="personalDetails?.state" class="text-sm"><strong>State:</strong> {{ personalDetails.state }}</div>
      </div>
    </div>
    

      <!-- Sidebar Menu -->
      <div class="mt-8 w-full font-poppins left-sidebar-sub">
        <!-- Personal Details Button -->
        <button (click)="selectedSection = 'personal'" 
                [ngClass]="{'bg-[#00558C] text-white': selectedSection === 'personal', 'bg-gray-200': selectedSection !== 'personal'}" 
                class="w-full py-2 text-center rounded-lg font-semibold flex items-center justify-center personal-detail-button">
            <img src="assets/icons/profile-details.svg" alt="Personal Icon" class="w-5 h-5 mr-2 left-sidebar-sub-logo" />
            Personal Details
        </button>
    
        <!-- Academic Details Button -->
        <button (click)="selectedSection = 'academic'" 
                [ngClass]="{'bg-[#00558C] text-white': selectedSection === 'academic', 'bg-gray-200': selectedSection !== 'academic'}" 
                class="w-full py-2 text-center rounded-lg font-semibold flex items-center justify-center academic-details-button">
            <img src="assets/icons/academic-details.svg" alt="Academic Icon" class="w-5 h-5 mr-2 left-sidebar-sub-logo" />
            Academic Details
        </button>
    
        <!-- Entry Test Details Button -->
        <button (click)="selectedSection = 'language'" 
                [ngClass]="{'bg-[#00558C] text-white': selectedSection === 'language', 'bg-gray-200': selectedSection !== 'language'}" 
                class="w-full py-2 text-center rounded-lg font-semibold flex items-center justify-center test-details-button">
            <img src="assets/icons/language-details.svg" alt="Entry Test Icon" class="w-5 h-5 mr-2 left-sidebar-sub-logo " />
            Test Details
        </button>
    
        <!-- Logout Button -->
        <button (click)="logout()" 
                class="w-full py-2 text-center bg-red-400 text-white rounded-lg font-semibold flex items-center justify-center logout-button">
            <!-- <img src="assets/icons/logout.svg" alt="Logout Icon" class="w-5 h-5 mr-2" /> -->
            Logout
        </button>
      </div>
  </div>

  <!-- Main Content -->
  <div class="w-3/4 bg-white rounded-lg ml-8 p-8 right-container">
    <div class="flex justify-between items-center mb-6 account-container">
      <h1 class="text-2xl font-bold">My Account</h1>
      <button (click)="viewResults()" class="bg-[#008DEA] text-white py-2 px-4 rounded-lg">Go to Results</button>
    </div>

    <!-- Personal Details Section -->
    <div *ngIf="selectedSection === 'personal'">
      <h2 class="text-[24px] font-semibold text-[#00558C] mb-4">Personal Details</h2>
      <div class="grid grid-cols-3 gap-6 personal-details-sub">
        <div>
          <label class="block text-sm text-[#000000] font-medium">First Name</label>
          <input type="text" readonly placeholder="e.g., Avish" class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.firstName">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Last Name</label>
          <input type="text" readonly placeholder="e.g., Shetty" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.lastName">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Email</label>
          <input type="email" readonly placeholder="e.g., satyam@example.com" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.email">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Country you are planning to go?</label>
          <input type="text" readonly placeholder="e.g., USA" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.targetCountry">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Which Degree?</label>
          <input type="text" readonly placeholder="e.g., Postgraduate" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.targetDegree">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Which Course?</label>
          <input type="text" readonly placeholder="e.g., Bioengineering" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.targetCourse">
        </div>
        <!-- <div>
          <label class="block text-sm font-medium text-[#000000]">Profession?</label>
          <input type="text" placeholder="e.g., Student" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.profession">
        </div> -->
        <div *ngIf="personalDetails?.months_of_experience">
          <label class="block text-sm font-medium text-[#000000]">Total Months of Experience</label>
          <input type="text" readonly placeholder="e.g., 24" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" [value]="personalDetails.months_of_experience" readonly>
        </div>
      </div>
    </div>

    <!-- Academic Details Section -->
    <div *ngIf="selectedSection === 'academic'">
      <h2 class="text-[24px] font-semibold text-[#00558C] mb-4">Academic Details</h2>
      <div *ngIf="personalDetails.academicHistory.undergraduateMarks.undergradCollegeName" class="grid grid-cols-3 gap-6 academic-details">
        <div>
          <label class="block text-sm font-medium text-[#000000]">College Name</label>
          <input type="text" readonly class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" 
                [value]="personalDetails.academicHistory.undergraduateMarks.undergradCollegeName">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Percentage</label>
          <input type="text" readonly class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" 
                [value]="personalDetails.academicHistory.undergraduateMarks.percentage">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Degree</label>
          <input type="text" readonly class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" 
                [value]="personalDetails.academicHistory.undergraduateMarks.degreeName">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Backlogs</label>
          <input type="text" readonly class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" 
                [value]="personalDetails.academicHistory.undergraduateMarks.degreeBacklogs">
        </div>
      </div>
      <div *ngIf="personalDetails.academicHistory.highSchoolMarks" class="grid grid-cols-3 gap-6">
        <div>
          <label class="block text-sm font-medium text-[#000000]">High School Name</label>
          <input type="text" readonly class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" 
                [value]="personalDetails.academicHistory.highSchoolMarks.highSchoolName">
        </div>
        <div>
          <label class="block text-sm font-medium text-[#000000]">Score</label>
          <input type="text" readonly class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" 
                [value]="personalDetails.academicHistory.highSchoolMarks.highSchoolScore+ ' / ' +personalDetails.academicHistory.highSchoolMarks.highSchoolScoreSystem" >
        </div>
      </div>
    </div>

    <!-- Language Exam Details Section -->
    <div *ngIf="selectedSection === 'language'">
      <h2 class="text-[24px] font-semibold text-[#00558C] mb-4">Entry Test Details</h2>
      <div *ngIf="personalDetails?.entryTests?.length">
        <div class="grid grid-cols-2 gap-6 test-details">
          <div>
            <label class="block text-sm font-medium text-[#000000]">Test Type *</label>
            <input type="text" readonly [value]="personalDetails.entryTests[0]?.testName" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" readonly>
          </div>
          <div *ngIf="personalDetails.entryTests[0]?.testScore">
            <label class="block text-sm font-medium text-[#000000]">Test Score *</label>
            <input type="text" readonly [value]="personalDetails.entryTests[0]?.testScore" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" readonly>
          </div>
        </div>

        <!-- Aptitude Exam Section (if applicable) -->
        <div *ngIf="personalDetails?.entryTests?.length > 1 && personalDetails.entryTests[1]?.testScore" class="grid grid-cols-2 gap-6 mt-4">
          <div>
            <label class="block text-sm font-medium text-[#000000]">Test Type</label>
            <input type="text" readonly [value]="personalDetails.entryTests[1]?.testName" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" readonly>
          </div>
          <div>
            <label class="block text-sm font-medium text-[#000000]">Test Score</label>
            <input type="text" readonly [value]="personalDetails.entryTests[1]?.testScore" class="mt-1 block w-full rounded-md  border border-gray-300 shadow-sm p-2 focus:border-gray-300 focus:outline-none" readonly>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
