export const COUNTRY_OPTIONS = [
  { 
    name: 'US', 
    flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754442825United%20States%20of%20America%201.png' 
  },
  { 
    name: 'UK', 
    flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754474318UK-flag.png' 
  },
  { name: 'Canada',
    flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754513112Canada-flag.png' 
  },
  { name: 'Germany',
    flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754544994germany-flag.png' 
  },
  { name: 'Australia',
    flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754573315Australia%201.png' 
  }
];

export const HIGH_SCHOOL_BOARDS = [
  'CBSE (Central Board of Secondary Education)',
  'Assam Higher Secondary Education Council (AHSEC)',
  'Bihar School Examination Board (BSEB)','Cambridge International (CIE)',
  'Chhattisgarh Board of Secondary Education (CGBSE)',
  'CISCE (Council for the Indian School Certificate Examinations)',
  'Goa Board of Secondary and Higher Secondary Education (GBSHSE)',
  'Gujarat Secondary and Higher Secondary Education Board (GSEB)',
  'Haryana Board of School Education (HBSE)',
  'Himachal Pradesh Board of School Education (HPBOSE)',
  'IB (International Baccalaureate)',
  'Jammu and Kashmir State Board of School Education (JKBOSE)','Jharkhand Academic Council (JAC)',
  'Karnataka Pre-University Education Board (PUE)','Kerala Higher Secondary Examination Board (DHSE)',
  'Madhya Pradesh Board of Secondary Education (MPBSE)',
  'Maharashtra State Board of Secondary and Higher Secondary Education (MSBSHSE)',
  'Manipur Council of Higher Secondary Education (COHSEM)',
  'Meghalaya Board of School Education (MBOSE)',
  'Mizoram Board of School Education (MBSE)',
  'Nagaland Board of School Education (NBSE)',
  'NIOS (National Institute of Open Schooling)',
  'Odisha Council of Higher Secondary Education (CHSE)',
  'Punjab School Education Board (PSEB)',
  'Rajasthan Board of Secondary Education (RBSE)',
  'Tamil Nadu State Board of School Examination (TNSBSE)',
  'Telangana State Board of Intermediate Education (TSBIE)',
  'Tripura Board of Secondary Education (TBSE)',
  'Uttar Pradesh Board of Secondary Education (UPMSP)',
  'Uttarakhand Board of School Education (UBSE)',
  'West Bengal Council of Higher Secondary Education (WBCHSE)',
  'Others'
];

export const COURSE_OPTIONS = [
  'Computer Science',
  'Data Science',
  'Information Technology',
  'MBA','Cybersecurity',
  'Finance',
  'Mechanical Engineering',
  'Economics',
  'Electrical Engineering',
  'Artificial Intelligence', 'Software Engineering',
];

export const LIST_OF_COURSES = [
  "Aeronautical Engineering","Accounting","Bioengineering", 
  "Business Administration", "Business Analytics", "Chemical Engineering", 
  "Civil Engineering", "Computer Science", "Cyber Security", "Data Science", "Economics", "Electrical Engineering", "Science",
  "Energy Science and Technology",  "Fashion", "Finance", "Games Design",
  "Human Resources Management", "Information Technology", "Industrial Design", 
  "International Trade", "Investment & Finance", "Management","Mathematics", "Mechanical Engineering", 
  "National Security Studies", "Physics", "Plastics Engineering Technology", "Polymer and Fiber Engineering", 
  "Quantitative Finance", "Robotics Engineering", "Sports Marketing", "Marketing", 
];