<!-- src/app/components/cost-living-chart/cost-living-chart.component.html -->
<div class="chart-container">
    
    <div class="chart-wrapper">
      <canvas baseChart
        [data]="doughnutChartData"
        [options]="doughnutChartOptions"
        [type]="doughnutChartType">
      </canvas>
    </div>
    <!-- <div class="text-center mt-4 font-bold font-poppins">
        Total: {{ getCurrencySymbol() }}{{ totalAmount }}
    </div> -->
  </div>
