import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private snackbarService: SnackbarService,) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Check if user is logged in (has valid token)
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');
    
    if (token && uid) {
        return true; // Allow access only if both token and uid exist
    }

     // Get puid from route params
     const puid = route.paramMap.get('puid');

     // Navigate to login with puid if it exists
     if (puid) {
       this.router.navigate(['/student-login', puid]);
     } else {
       this.router.navigate(['/student-login']);
     }
     this.snackbarService.show('Please Login Again', 'warning');
 
     return false;
  }
}