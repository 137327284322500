// src/app/shared/components/snackbar/snackbar.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-snackbar',
  template: `
    <div *ngIf="show" 
         [@slideInOut]
         class="snackbar" 
         [ngClass]="type">
      <div class="snackbar-content">
        <span class="message">{{ message }}</span>
        <button *ngIf="showClose" class="close-button" (click)="closeSnackbar()">×</button>
      </div>
    </div>
  `,
  styles: [`
    .snackbar {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 300px;
      max-width: 500px;
      padding: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }

    .snackbar-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .success {
      background-color: #4CAF50;
      color: white;
    }

    .error {
      background-color: #f44336;
      color: white;
    }

    .info {
      background-color: #2196F3;
      color: white;
    }

    .warning {
      background-color: #ff9800;
      color: white;
    }

    .message {
      margin-right: 8px;
    }

    .close-button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 20px;
      padding: 0 4px;
    }
  `],
  animations: [
    trigger('slideInOut', [
      state('void', style({
        transform: 'translateY(100%)',
        opacity: 0
      })),
      transition('void => *', [
        animate('300ms ease-out', style({
          transform: 'translateY(0)',
          opacity: 1
        }))
      ]),
      transition('* => void', [
        animate('300ms ease-in', style({
          transform: 'translateY(100%)',
          opacity: 0
        }))
      ])
    ])
  ]
})
export class SnackbarComponent implements OnInit, OnDestroy {
  show = false;
  message = '';
  type = 'info';
  duration = 1500;
  showClose = true;
  private timeoutId: any;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  showMessage(message: string, type: string = 'info', duration: number = 3000, showClose: boolean = true): void {
    this.message = message;
    this.type = type;
    this.duration = duration;
    this.showClose = showClose;
    this.show = true;

    if (duration > 0) {
      this.setAutoHide();
    }
  }

  closeSnackbar(): void {
    this.show = false;
    this.clearTimeout();
  }

  private setAutoHide(): void {
    this.clearTimeout();
    this.timeoutId = setTimeout(() => {
      this.closeSnackbar();
    }, this.duration);
  }

  private clearTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}