import { Directive, ElementRef, Input, Renderer2, OnDestroy } from '@angular/core';

@Directive({
  selector: '[customTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input('customTooltip') tooltipText: string = '';
  private tooltip: HTMLElement | null = null;
  private mouseEnterListener: (() => void) | null = null;
  private mouseLeaveListener: (() => void) | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.mouseEnterListener = this.renderer.listen(this.el.nativeElement, 'mouseenter', () => {
      this.showTooltip();
    });

    this.mouseLeaveListener = this.renderer.listen(this.el.nativeElement, 'mouseleave', () => {
      this.hideTooltip();
    });
  }

  private showTooltip(): void {
    if (this.tooltip) {
      this.hideTooltip();
    }

    this.tooltip = this.renderer.createElement('div');
    if (!this.tooltip) return;

    this.renderer.addClass(this.tooltip, 'custom-tooltip');
    const text = this.renderer.createText(this.tooltipText);
    this.renderer.appendChild(this.tooltip, text);
    this.renderer.appendChild(document.body, this.tooltip);

    // Calculate position
    const hostRect = this.el.nativeElement.getBoundingClientRect();
    if (this.tooltip) {
      const tooltipRect = this.tooltip.getBoundingClientRect();
      
      // Get scroll positions
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollX = window.scrollX || window.pageXOffset;

      // Calculate absolute position
      const top = hostRect.top + scrollY - tooltipRect.height - 8; // 8px offset from element
      const left = hostRect.left + scrollX + (hostRect.width - tooltipRect.width) / 2;

      // Set position
      this.renderer.setStyle(this.tooltip, 'top', `${top}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
    }
  }

  private hideTooltip(): void {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }

  ngOnDestroy(): void {
    if (this.mouseEnterListener) {
      this.mouseEnterListener();
    }
    if (this.mouseLeaveListener) {
      this.mouseLeaveListener();
    }
    this.hideTooltip();
  }
}