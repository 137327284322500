import { Component, Input, OnInit, EventEmitter, Output, HostListener, ViewChild, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-cost-of-living-chart',
  templateUrl: './cost-of-living-chart.component.html',
  styleUrls: ['./cost-of-living-chart.component.scss']
})
export class CostOfLivingChartComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() livingData: any;
  @Input() title: string = '';

  totalAmount: number = 0;

  public doughnutChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            return ` $${context.raw}`;
          }
        }
      }
    }
  };

  // public doughnutChartOptions: ChartConfiguration['options'] = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       display: true,
  //       position: 'right', // Legend on the right
  //       labels: {
  //         generateLabels: (chart) => {
  //           const data = chart.data.datasets?.[0]?.data || []; // Safely access data
  //           const labels = chart.data.labels || []; // Safely access labels
  //           const backgroundColors = chart.data.datasets?.[0]?.backgroundColor as string[] || []; // Cast to string[]
  
  //           return labels.map((label, index) => ({
  //             text: `${label}: $${data[index] || 0}`, // Append value
  //             fillStyle: backgroundColors[index] || '#000', // Fallback color
  //             strokeStyle: backgroundColors[index] || '#000',
  //             lineWidth: 0,
  //             hidden: !chart.getDatasetMeta(0)?.data?.[index]?.active, // Safely access active state
  //             index: index,
  //           }));
  //         },
  //       },
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (context: any) {
  //           return ` $${context.raw}`;
  //         },
  //       },
  //     },
  //   },
  // };
  

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: ['Food', 'Stay', 'Transport', 'Others'],
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: ['#4CAF50', '#2196F3', '#FFC107', '#FF5722'],
        borderWidth: 0,
        hoverOffset: 4
      }
    ]
  };

  public doughnutChartType: ChartType = 'doughnut';

  ngOnInit() {
    console.log('Chart Component Init - Living Data:', this.livingData);
    console.log('Chart Component Init - Title:', this.title);
    if (this.livingData) {
      this.formatData();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    console.log('Cost Living Chart Changes Detected:', changes);
    if (changes['livingData'] && this.livingData) {
      console.log('New Living Data Received:', this.livingData);
      this.formatData();
    }
  }

  private extractNumber(str: string): number {
    // Remove $ sign and any other non-digit characters except decimal point
    return parseFloat(str.replace(/[^0-9.]/g, ''));
  }

  formatData(): void {
    if (this.livingData) {
      console.log('Formatting data:', this.livingData);
      
      this.totalAmount = this.extractNumber(this.livingData.total);
      
      this.doughnutChartData.datasets[0].data = [
        this.extractNumber(this.livingData.food),
        this.extractNumber(this.livingData.stay),
        this.extractNumber(this.livingData.transport),
        this.extractNumber(this.livingData.others)
      ];

      if (this.chart) {
        this.chart.update();
      }
    }
  }

  getCurrencySymbol(): string {
    const currentFilterStr = localStorage.getItem('currentFilter');
    if (currentFilterStr) {
      try {
        const currentFilter = JSON.parse(currentFilterStr);

        switch (currentFilter.country) {
          case 'US':
            return '$';
          case 'Germany':
            return '€';
          case 'UK':
            return '£';
          case 'Australia':
            return 'AUD ';
          default:
            return '$'; 
        }
      } catch (error) {
        console.error('Error parsing currentFilter from localStorage:', error);
        return '$'; 
      }
    } else {
      console.warn('currentFilter not found in localStorage');
      return '$'; // Fallback if no data in localStorage
    }
  }
  
}
