// src/app/configs/currency.config.ts

export interface BudgetRange {
    label: string;
    value: string;
  }
  
  export interface CurrencyConfig {
    code: string;
    symbol: string;
    budgetRanges: BudgetRange[];
  }
  
  export interface CountryCurrencyMap {
    [key: string]: CurrencyConfig;
  }
  
  export const INR_BUDGET_RANGES: BudgetRange[] = [
    { label: '20L - 40L', value: '20-40' },
    { label: '40L - 60L', value: '40-60' },
    { label: '60L - 80L', value: '60-80' },
    { label: '80L - 1Cr', value: '80-100' },
    { label: '1Cr & Above', value: '100' }
  ];
  
  export const COUNTRY_CURRENCY_CONFIG: CountryCurrencyMap = {
    'US': {
      code: 'USD',
      symbol: '$',
      budgetRanges: [
        { label: '$25K - $50K', value: '25-50' },
        { label: '$50K - $75K', value: '50-75' },
        { label: '$75K - $100K', value: '75-100' },
        { label: '$100K & Above', value: '100' }
      ]
    },
    'UK': {
      code: 'GBP',
      symbol: '£',
      budgetRanges: [
        { label: '£20K - £40K', value: '20-40' },
        { label: '£40K - £60K', value: '40-60' },
        { label: '£60K - £80K', value: '60-80' },
        { label: '£80K & Above', value: '80' }
      ]
    },
    'Australia': {
      code: 'AUD',
      symbol: 'A$',
      budgetRanges: [
        { label: 'A$35K - A$70K', value: '35-70' },
        { label: 'A$70K - A$100K', value: '70-100' },
        { label: 'A$100K - A$130K', value: '100-130' },
        { label: 'A$130K & Above', value: '130' }
      ]
    },
    'Germany': {
      code: 'EUR',
      symbol: '€',
      budgetRanges: [
        { label: '€23K - €45K', value: '23-45' },
        { label: '€45K - €70K', value: '45-70' },
        { label: '€70K - €90K', value: '70-90' },
        { label: '€90K & Above', value: '90' }
      ]
    }
  };

  export const DEFAULT_PLACEHOLDERS = {
    currency: { label: 'Select currency', value: '' },
    budgetRange: { label: 'Select budget range', value: '' }
  };
  
  export function getCurrencyOptions(targetCountry: string): BudgetRange[] {
    const options: BudgetRange[] = [
      DEFAULT_PLACEHOLDERS.currency,  
      { label: 'INR', value: 'INR' }
  ];
    const countryCurrency = COUNTRY_CURRENCY_CONFIG[targetCountry];
    if (countryCurrency) {
      options.push({ label: countryCurrency.code, value: countryCurrency.code });
    }
    return options;
  }
  
  export function getBudgetRangeOptions(currency: string, targetCountry: string): BudgetRange[] {
    const defaultOptions = [DEFAULT_PLACEHOLDERS.budgetRange];  // Add default placeholder
    
    if (!currency) return defaultOptions;
    
    if (currency === 'INR') {
      return [...defaultOptions, ...INR_BUDGET_RANGES];
    }
    const countryCurrency = COUNTRY_CURRENCY_CONFIG[targetCountry];
    return [...defaultOptions, ...(countryCurrency?.budgetRanges || INR_BUDGET_RANGES)];
  }