// src/app/shared/services/snackbar.service.ts
import { Injectable, ComponentRef, ApplicationRef, ComponentFactoryResolver, Injector, EmbeddedViewRef, Type } from '@angular/core';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private snackbarComponentRef: ComponentRef<SnackbarComponent> | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  show(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'info', duration: number = 3000): void {
    this.hideCurrentSnackbar();
    this.showNewSnackbar(message, type, duration);
  }

  private showNewSnackbar(message: string, type: string, duration: number): void {
    // Create component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(SnackbarComponent)
      .create(this.injector);

    // Attach component to the appRef so that it will be dirty checked
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append to body
    document.body.appendChild(domElem);

    // Show the snackbar
    componentRef.instance.showMessage(message, type, duration);

    // Store the component reference
    this.snackbarComponentRef = componentRef;
  }

  private hideCurrentSnackbar(): void {
    if (this.snackbarComponentRef) {
      this.appRef.detachView(this.snackbarComponentRef.hostView);
      this.snackbarComponentRef.destroy();
      this.snackbarComponentRef = null;
    }
  }
}