import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UserInfoPageComponent } from './user-info-page/user-info-page.component';
import { ResultsComponent } from './results/results.component';
import { UniversityDetailComponent } from './university-detail/university-detail.component';
import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { StudentRegisterComponent } from './student-register/student-register.component';
import { PartnerLoginComponent } from './partner-login/partner-login.component';
import { PartnerDashboardComponent } from './partner-dashboard/partner-dashboard.component';
import { PartnerRegisterComponent } from './partner-register/partner-register.component';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { AppComponent } from './app.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { ProfileHelperComponent } from './components/profile-helper/profile-helper.component';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  { path: '', component: LandingPageComponent },
  

  { path: 'user-info', component: UserInfoPageComponent },
  { path: 'user-info/:puid', component: UserInfoPageComponent },

  { path: 'results', component: ResultsComponent, canActivate: [AuthGuard] },
  { path: 'results/:puid', component: ResultsComponent, canActivate: [AuthGuard] },

  { path: 'university-detail/:course_id', component: UniversityDetailComponent, canActivate: [AuthGuard] },
  { path: 'university-detail/:course_id/:puid', component: UniversityDetailComponent, canActivate: [AuthGuard] },

  { path: 'wishlist', component: WishlistPageComponent },
  { path: 'wishlist/:puid', component: WishlistPageComponent },

  { path: 'student-login', component: StudentLoginComponent },
  { path: 'student-login/:puid', component: StudentLoginComponent },

  { path: 'partner-login', component: PartnerLoginComponent },
  { path: 'partner-login/:puid', component: PartnerLoginComponent },

  { path: 'partner-dashboard', component: PartnerDashboardComponent },
  { path: 'partner-dashboard/:puid', component: PartnerDashboardComponent },

  { path: 'personal-details', component: PersonalDetailsComponent, canActivate: [AuthGuard] },
  { path: 'personal-details/:puid', component: PersonalDetailsComponent, canActivate: [AuthGuard] },

  { path: 'profile-helper', component: ProfileHelperComponent },
  { path: 'profile-helper/:puid', component: ProfileHelperComponent },


  { path: 'student/details/:id', component: StudentDetailsComponent },

  // { path: '**', redirectTo: '/student-login', pathMatch: 'full' },
  { path: ':puid', component: LandingPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64] // Adjust this value based on your header height
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

