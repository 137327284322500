<div class="main-container">
  <div class="background-image"></div>
  <div class="content-wrapper">
    <!-- Header Section -->
    <header class="flex justify-between items-center py-6 px-8 bg-white">
      <div class="flex items-center">
        <img *ngIf="partnerLogo" 
            [src]="partnerLogo" 
            alt="Partner Logo" 
            class="max-h-[34px] md:max-h-[40px] lg:max-h-[50px] 
                    min-h-[20px] w-auto 
                    max-w-[176px] md:max-w-[200px] lg:max-w-[250px]
                    object-contain" />
      </div>
      <div class="flex space-x-4">
        <div>
        <button (click)="navigateToLogin()" class="text-[#008DEA] border border-blue-600  mt-2 py-3 px-8 rounded-[8px] font-poppins hover:bg-blue-50 transition mobile-view-button">
          Login
        </button>
        </div>
        <div class="find-your-college-mobile-view">
          <button (click)="navigateToRegister()" class="bg-[#008DEA] text-white font-[600px] mt-2 py-3 px-8 rounded-[8px] shadow-lg font-poppins hover:bg-blue-700 transition">
            Find Your College!
          </button>
        </div>
      </div>
    </header>

    <!-- Main Section -->
    <div class="flex-grow flex flex-col items-center justify-center text-center main-continer-laptop">
      <div class="flex main-conatiner justify-center mt-6">
        <!-- University Image Placeholder -->
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342617936uni.png" alt="University Image" class="mb-2 w-[104px] h-[80px] object-cover mr-4" />
        <h2 class="text-7xl font-bold text-[#008DEA] font-poppins">Find Your Next</h2>
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342666288student.png" alt="Person Image" class="mb-2 w-[104px] h-[80px] object-cover ml-4" /> 
      </div>
      <div>
        <h2 class="text-7xl font-bold text-[#008DEA] font-poppins">Academic Adventure!</h2>
      </div>
      <div class="mb-2 mt-4">
        <p class="text-gray-600 text-lg font-poppins">Explore and compare top universities abroad to find</p>
        <p class="text-gray-600 text-lg font-poppins">the perfect fit for your academic goals.</p>
      </div>
      <!-- Call to Action Button -->
      <button (click)="navigateToRegister()" class="bg-[#008DEA] text-white mt-12 py-3 px-8 rounded-[8px] shadow-lg hover:bg-blue-700 transition font-poppins">
        Find Your College!
      </button>
    </div>

    
      <!-- For Mobile View -->
      <div class="flex-grow flex flex-col items-center justify-center text-center main-continer-mobile mt-24">
        <div class="flex main-conatiner justify-center">
          <!-- University Image Placeholder -->
          <h2 class="text-7xl font-bold text-[#008DEA] font-poppins header-line-1">Find Your Next</h2>
        </div>

        <div class="flex mb-4">
          <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342617936uni.png" alt="University Image" class="mb-2 w-[70px] h-[50px] object-cover mr-4" />
          <h2 class="text-7xl font-bold text-[#008DEA] font-poppins header-line-2">Academic Adventure!</h2>
          <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342666288student.png" alt="Person Image" class="mb-2 w-[70px] h-[50px] object-cover ml-4" />
        </div>

        <div class=" mt-4 info-below mb-4">
          <p class="text-gray-600 text-[14px] font-poppins mb-4">Explore and compare top universities abroad to find the perfect fit for your academic goals.</p>
          <!-- <p class="text-gray-600 text-[14px] font-poppins">the perfect fit for your academic goals.</p> -->
        </div>
        <!-- Call to Action Button -->
        <button (click)="navigateToRegister()" class="bg-[#008DEA] text-white mt-12 py-3 px-8 rounded-[8px] shadow-lg hover:bg-blue-700 transition font-poppins">
          Find Your College!
        </button>
      </div>
    
    <!-- Decorative Boxes (Background Shapes) -->
    <!-- <div class="fixed left-4 bottom-4 space-y-4 z-10 color-box-1">
      <div>
      <div class="bg-[#CCE8FB] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex gap-4">
        <div class="bg-[#FFD7A94D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#FFE1594D] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex gap-4">
        <div class="bg-[#59DAFF4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#40BEEC4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#9993A14D] w-28 h-28 rounded-lg"></div>
      </div>
    </div>

    <div class="fixed right-0 bottom-4 space-y-4 z-10 color-box-2">
      <div class="flex justify-end">
        <div class="bg-[#CCE8FB] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex justify-end gap-4">
        <div class="bg-[#FFD7A94D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#FFE1594D] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex justify-end gap-4">
        <div class="bg-[#59DAFF4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#40BEEC4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#9993A14D] w-28 h-28 rounded-lg"></div>
      </div>
    </div>

    <div class="fixed left-4 bottom-4 space-y-4 z-10 color-box-1">
      <div>
      <div class="bg-[#CCE8FB] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex gap-4">
        <div class="bg-[#FFD7A94D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#FFE1594D] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex gap-4">
        <div class="bg-[#59DAFF4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#40BEEC4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#9993A14D] w-28 h-28 rounded-lg"></div>
      </div>
    </div>

    <div class="fixed right-0 bottom-4 space-y-4 z-10 color-box-2">
      <div class="flex justify-end">
        <div class="bg-[#CCE8FB] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex justify-end gap-4">
        <div class="bg-[#FFD7A94D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#FFE1594D] w-28 h-28 rounded-lg"></div>
      </div>
      <div class="flex justify-end gap-4">
        <div class="bg-[#59DAFF4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#40BEEC4D] w-28 h-28 rounded-lg"></div>
        <div class="bg-[#9993A14D] w-28 h-28 rounded-lg"></div>
      </div>
    </div> -->
  <!-- 
    <div class="fixed left-[-20px] bottom-20 space-y-4 z-10 color-box-3">
      
      <div class="flex gap-1">
        <div class="bg-[#FFD7A94D] w-16 h-16 rounded-lg"></div>
        <div class="bg-[#FFE1594D] w-16 h-16 rounded-lg"></div>
      </div>
      <div class="flex gap-1">
        <div class="bg-[#59DAFF4D] w-16 h-16 rounded-lg"></div>
        <div class="bg-[#40BEEC4D] w-16 h-16 rounded-lg"></div>
        <div class="bg-[#9993A14D] w-16 h-16 rounded-lg"></div>
      </div>
    </div>

    <div class="fixed right-[-25px] bottom-20 space-y-4 z-10 color-box-4">
      <div class="flex justify-end">
        <div class="bg-[#CCE8FB] w-16 h-16 rounded-lg"></div>
      </div>
      <div class="flex justify-end gap-1">
        <div class="bg-[#FFD7A94D] w-16 h-16 rounded-lg"></div>
        <div class="bg-[#FFE1594D] w-16 h-16 rounded-lg"></div>
      </div>
      <div class="flex justify-end gap-1">
        <div class="bg-[#59DAFF4D] w-16 h-16 rounded-lg"></div>
        <div class="bg-[#40BEEC4D] w-16 h-16 rounded-lg"></div>
        <div class="bg-[#9993A14D] w-16 h-16 rounded-lg"></div>
      </div>
    </div> -->
    <!-- <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1735554115241MacBook%20Air%20-%2060.webp"
    alt="University Image"
      class="w-full h-full" /> -->
  </div>
</div>
