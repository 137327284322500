import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { PartnerService } from '../services/partner.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
  personalDetails: any = {};
  selectedSection: string = 'personal';
  partnerLogo: string = ''; 
  puid: string = "";
  private baseUrl: string = environment.apiBaseUrl;
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, private partnerService:PartnerService,) { }

  ngOnInit(): void {
    this.getStudentDetails();
    const puid = this.activatedRoute.snapshot.paramMap.get('puid')
    if (puid) {
      this.puid = puid;
      this.fetchPartnerLogo(puid);
    }
  }

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.logo; 
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }

  getStudentDetails(): void {
    const uid = localStorage.getItem('uid'); 

    if (uid) {
      this.http.get(`${this.baseUrl}/student-choices/get-student-details/${uid}`).subscribe(
        (response: any) => {
          if (response.success) {  
            this.personalDetails = response.data;  
            this.populateFormWithDetails();   
          } else {
            console.error('Error fetching student details:', response.message);
          } 
        },
        (error: any) => {
          console.error('Error fetching student details', error);
        }
      );
    } else {
      console.error('UID not found in local storage');
    }
  }

  populateFormWithDetails(): void {
    console.log(this.personalDetails);
  }

  viewResults() {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,123)
    if(puid){
      this.router.navigate(['/results/'+puid]);
    }
    else{
      this.router.navigate(['/results']);
    }
  }

  logout(): void {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    localStorage.removeItem('access_token');  
    localStorage.removeItem('uid');           
    localStorage.removeItem('results');       
    localStorage.removeItem('appliedFilters'); 
    localStorage.removeItem('currentFilter'); 
    localStorage.clear();
    
    if(puid){
      this.router.navigate(['/student-login/'+puid]);
    }
    else{
      this.router.navigate(['/student-login']);
    }
  }


}
