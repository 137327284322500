import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../services/partner.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms'; // Import for form control
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { SnackbarService } from '../services/snackbar.service';
import { environment } from '../../environments/environment';
import { catchError, switchMap, throwError, of } from 'rxjs';


interface UserResponse {
  isExistingUser: boolean;
}

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated 
})

export class StudentLoginComponent {
  phoneForm: FormGroup;
  mobile: string = '';
  // otpDigits: string[] = ['', '', '', '', '', '']; // Array to store individual OTP digits
  isOtpMode: boolean = false;
  userType: string = 'student';
  isExistingUser: boolean = false;
  countdown: number = 59; 
  countdownInterval: any;
  puid: string = "";
  partnerLogo: string = ''; 
  partnerConsent: string = '';
  isReady = false;
  showExclamation: boolean = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  private loginBaseUrl = environment.registerBaseUrl;
  private CheckUrl = environment.apiBaseUrl;
    
  constructor(private http: HttpClient,
     private router: Router,
     private activatedRoute: ActivatedRoute,
     private partnerService:PartnerService,
     private formBuilder: FormBuilder,
     private snackbarService: SnackbarService,
  ) {
    this.phoneForm = this.formBuilder.group({
      mobile: [undefined, [Validators.required]],  // ngx-intl-tel-input handles phone validation
      otpDigits: this.formBuilder.array([
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
      ]),
    });
    this.phoneForm.get('mobile')?.valueChanges.subscribe(value => {
      if (value) {
        const phoneNumber = value.number?.replace(/\D/g, '');
        const countryCode = value.countryCode;
        this.showExclamation = !this.isValidPhoneNumber(phoneNumber, countryCode);
      }
    });
  }
  

  ngOnInit(): void {
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    
    if (!hasRefreshed) {
      sessionStorage.setItem('hasRefreshed', 'true');
      window.location.reload();
      return;
    }
    const puid = this.activatedRoute.snapshot.paramMap.get('puid')
    if (puid) {
      this.puid = puid;
      this.fetchPartnerLogo(puid);
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('initialFilter');
    localStorage.removeItem('loglevel');
    localStorage.removeItem('results');
    localStorage.removeItem('uid');
    localStorage.removeItem('currentFilter');
    localStorage.removeItem('created');
    this.isReady = true;
  }

  isValidPhoneNumber(phoneNumber: string | undefined, countryCode: string): boolean {
    if (!phoneNumber) return true;
    
    switch(countryCode) {
      case 'IN':
        return phoneNumber.length === 10;
      case 'US':
        return phoneNumber.length === 10;
      default:
        return phoneNumber.length >= 8 && phoneNumber.length <= 15;
    }
  }

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.data.company.logo; 
        this.partnerConsent = response.data.company.consent;
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
        this.partnerConsent = "By providing the mobile number, I agree to override the registry on NCPR/NDNC and authorize us to connect me and send relevant notifications via call, Email, SMS and WhatsApp.";
      }
    );
  }

  get otpDigits(): FormArray {
    return this.phoneForm.get('otpDigits') as FormArray;
  }

  // Function to combine the OTP digits into a single string
  getOtpString(): string {
    return this.otpDigits.controls.map(control => control.value).join('');
  }

  startOtpMode() {
    this.isOtpMode = true;
    this.startCountdown(); // Start the countdown
  }

  resendOtp() {
    // Reset the countdown
    this.countdown = 59;
    this.startCountdown(); 

    this.sendMobileNumber();
  }

  editMobileNumber() {
    // Stop the countdown
    clearInterval(this.countdownInterval);
    
    // Switch back to mobile input mode
    this.isOtpMode = false;
  }


  moveFocus(event: any, index: number) {
    const currentInput = event.target;
    const value = currentInput.value;
    
    // Handle regular input
    if (value.length === 1) {
      // Move to next input if available
      if (index < 5) {
        const nextInput = document.querySelectorAll('.otp-box')[index + 1] as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
    // Handle backspace
    else if (event.key === 'Backspace') {
      // Clear current input if it has a value
      if (value.length > 0) {
        currentInput.value = '';
        return;
      }
      // Move to previous input if current is empty
      if (index > 0) {
        const prevInput = document.querySelectorAll('.otp-box')[index - 1] as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
          // Optional: Also clear the previous input
          prevInput.value = '';
          this.otpDigits.controls[index - 1].setValue('');
        }
      }
    }
  }
  
  // Add this new function to handle keydown specifically
  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace') {
      const currentInput = event.target as HTMLInputElement;
      const currentValue = currentInput.value;
      
      // If current input is empty and we're not at the first input
      if (currentValue === '' && index > 0) {
        const prevInput = document.querySelectorAll('.otp-box')[index - 1] as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
          prevInput.value = '';
          this.otpDigits.controls[index - 1].setValue('');
        }
        event.preventDefault();
      }
    }
  }


    // Function to handle the countdown
  startCountdown() {
    this.countdown = 59; // Reset countdown time
    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }
  
  editMobile() {
    this.isOtpMode = false;
    clearInterval(this.countdownInterval);
  }

  sendMobileNumber() {
    const userType = 'student';
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    const currentPuid = puid;
    const phoneNumber = this.phoneForm.value.mobile.e164Number;

    const checkPhonePayload = {
      phoneNumber: phoneNumber
    };
  
    this.http.post(`${this.CheckUrl}/student-choices/check-phone-puid`, checkPhonePayload)
      .pipe(
        switchMap((phoneResponse: any) => {
          // If phone doesn't exist - new user case
          if (!phoneResponse.data.exists) {
            this.snackbarService.show(
              'You are a new user. Proceed with registration.',
              'warning',
              3000
            );
            
            // Navigate to user-info with puid if it exists
            if (currentPuid) {
              this.router.navigate(['/user-info', currentPuid]);
            } else {
              this.router.navigate(['/user-info']);
            }
            return of(null);
          }
          
          // If phone exists with same PUID, proceed with OTP
          if (phoneResponse.data.hasPuid && phoneResponse.data.puid === currentPuid) {
            const requestPayload: any = {
              mobile: phoneNumber,
              type: userType,
              usersAvailOtp: true,
              puid: currentPuid
            };
  
            return this.http.post<UserResponse>(`${this.loginBaseUrl}/users`, requestPayload);
          }
  
          // Phone exists with different PUID
          if (phoneResponse.data.hasPuid && phoneResponse.data.puid !== currentPuid) {
            this.snackbarService.show(
              'You have registered with different partner, either login with that link or register again with different number', 
              'warning',
              5000
            );
          } else {
            this.snackbarService.show(
              'This phone number is already registered. Please use a different number.',
              'warning',
              5000
            );
          }
          return throwError('Phone number exists with different PUID');
        }),
        catchError(error => {
          if (error === 'Phone number exists with different PUID') {
            return of(null);
          }
          console.error('Error in phone verification flow:', error);
          this.snackbarService.show('Failed to verify phone number. Please try again.', 'error');
          return of(null);
        })
      )
      .subscribe(
        (response: UserResponse | null) => {
          if (response) {
            console.log('Mobile number sent successfully', response);
            this.isExistingUser = response.isExistingUser;
            this.isOtpMode = true;
            this.snackbarService.show('Mobile number verified successfully!', 'success');
            this.startOtpMode();
          }
        },
        (error) => {
          console.error('Error sending mobile number', error);
          this.snackbarService.show('Failed to send mobile number. Please try again.', 'error');
        }
      );
  }

  verifyOtpAndLogin() {
    const otp = this.getOtpString();
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(this.phoneForm.value.mobile.e164Number,147)
    console.log(otp,148)
    this.http
      // .post('http://127.0.0.1:3000/api/users/login-with-mobile-otp', {
      .post(`${this.loginBaseUrl}/users/login-with-mobile-otp`, {
        mobile: this.phoneForm.value.mobile.e164Number,
        otp: otp 
      })
      .subscribe(
        (response: any) => {
          console.log(response, 57);
          localStorage.setItem('access_token', response.id);
          localStorage.setItem('uid', response.userDetails.uid);
          localStorage.setItem('parentId', response.userDetails.parentId);

          if (localStorage.getItem('savedFilters')) {
            localStorage.removeItem('savedFilters');
            console.log('Saved filters removed from localStorage');
          }

          if (localStorage.getItem('studentChoices')) {
            localStorage.removeItem('studentChoices');
            console.log('Student choices removed from localStorage');
          }

          this.snackbarService.show('Login successful!', 'success')

          if (this.isExistingUser) {
            if (puid) {
              this.router.navigate(['/personal-details/' + puid]);
            } else {
              this.router.navigate(['/personal-details']);
            }
          } else {
            if (puid) {
              this.router.navigate(['/user-info/' + puid]);
            } else {
              this.router.navigate(['/user-info']);
            }
          }
        },
        (error) => {
          console.error('Login failed', error);
          this.snackbarService.show('Login failed. Please check the OTP and try again.', 'error')
        }
      );
  }

  login() {
    if (this.isOtpMode) {
      this.verifyOtpAndLogin();
    } else {
      this.sendMobileNumber();
    }
  }
}
